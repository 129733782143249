var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center",staticStyle:{"gap":"1rem"}},_vm._l((_vm.data),function(card,index){return _c('div',{key:index,staticClass:"col-12 col-sm-5 col-md d-flex justify-content-between text-primary rounded align-items-center bg-dark p-2",on:{"mouseenter":function($event){return _vm.showCursorGlow(index)},"mouseleave":function($event){return _vm.hideCursorGlow(index)},"mousemove":(event) => _vm.handleMouseMove(event, index),"click":function($event){return _vm.toggleActive(index)}}},[(_vm.showGlow)?_c('div'):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"flex-grow":"1","max-width":"calc(100% )"}},[_c('div',{class:`title-container`},[_c('span',{staticClass:"font-weight-bold",class:[
            _vm.showGlow[index] &&
            _vm.animatedState[index] &&
            _vm.shouldAnimateTitle[index]
              ? 'title-animate'
              : 'title-static',
          ]},[_vm._v(" "+_vm._s(card.title)+" ")])]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"font-size":"40px"}},[_vm._v(_vm._s(_vm.evaluateValue(card.value)))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }