<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <card>
          <template v-slot:body>
            <div class="iq-email-list">
              <div class="row">
                <div class="col-12 col-md-10">
                  <CardTable :data="dataCards" />
                </div>
                <div class="col-12 col-md">
                  <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                </div>
              </div>
              <!-- <tab-nav :pills="true" vertical id="myTab" class="iq-email-ui nav flex-column nav-pills" @change="updateTab">
                <tab-nav-items li-class="nav-link active" :active="true" id="mail-inbox-tab" ariaControls="mail-inbox" role="tab" :ariaSelected="true">
                  <template v-slot:title> <i class="ri-mail-line" />Inbox<span class="badge badge-primary ml-2">4</span> </template>
                </tab-nav-items>
              </tab-nav> -->
              <!-- <h6 class="mt-4 mb-3">Filtros</h6>
              <ul class="iq-email-ui iq-email-label">
                <li>
                  <a href="#"
                    ><i class="ri-focus-fill text-primary"></i>Ver Todos</a
                  >
                </li>
                <li>
                  <a href="#"
                    ><i class="ri-focus-fill text-danger"></i>No Respondidos</a
                  >
                </li>
                <li>
                  <a href="#"
                    ><i class="ri-focus-fill text-success"></i>Completos</a
                  >
                </li>
              </ul> -->
            </div>
          </template>
        </card>
      </b-col>
      <b-col lg="12">
        <div class="table-responsive px-4 mt-3">
          <table
            id="datatable_numeraciones"
            class="table table-bordered table-responsive-md table-striped text-center"
          >
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Nombre</th>
                <th>Asunto</th>
                <th>Fecha</th>
                <th>Estado</th>
                <th>Nuevo mensaje</th>
                <th>Prioridad</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listaItems" :key="item.id">
                <td>{{ item.empresa.nombre }}</td>
                <td>
                  {{ item.persona.nombres + " " + item.persona.apellidos }}
                </td>
                <td>{{ item.asunto }}</td>
                <td>
                  {{
                    $moment
                      .tz(item.fecha, "America/Bogota")
                      .format("DD-MM-YYYY HH:mm")
                  }}
                </td>
                <td>{{ item.estado == 1 ? "Abierto" : "Cerrado" }}</td>
                <td>
                  <i
                    v-if="item.estadoTicket"
                    class="fa-solid fa-circle-exclamation text-danger"
                  ></i>
                  <span v-else>--</span>
                </td>
                <td :class="item.prioridad == 3 ? 'text-danger' : item.prioridad == 2 ? 'text-warning' : 'text-blue'">
                  {{ item.prioridad == 1 ? "Baja" : item.prioridad == 2 ? "Media" : "Alta" }}
                </td>

                <td>
                  <span class="pointer">
                    <!-- ><i v-b-tooltip.hover title="Ver mensajes" @click="$router.push('/helex/ChatTicket/'+ item.persona.id+ '/' + item.id)" class="fas fa-solid fa-eye px-1 text-success"></i> -->
                    <i v-b-tooltip.hover title="Ver mensajes" @click="verMensajes(item)" class="fas fa-solid fa-eye px-1 text-success"></i>
                    <i @click="cerrarTicket(item)" v-if="usuario.rol.nivel == 4" v-b-tooltip.hover title="Cerrar ticket" class="fas fa-solid fa-lock px-1 text-danger"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <!-- <b-modal
        v-model="show"
        id="compose-email-popup"
        size="lg"
        hide-footer
        hide-header
        content-class="border-0"
        dialog-class="m-0"
        static
        body-class="p-0"
        class="compose-popup modal-sticky-bottom-right modal-sticky-lg"
        cancel-title="Close"
      >
        <ComposeBody class="mb-0" :modal="true" @close="closeModal" />
      </b-modal> -->
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  components: {
    CardTable,
    BtnAddCardTable,
  },
  data() {
    return {
      listaItems: [
        {
          empresa: {
            nombre: null,
          },
          persona: {
            nombres: null,
            apellidos: null,
          },
        },
      ],
      logoEmpresa: "",
      usuario: { rol: { nivel: 0 } },
      listaTickets: [],
      dataTableInstance: null,
      dataCards: [
        // {
        //   title: "Todos los Tickets",
        //   value: () =>
        //     this.listaTickets.filter(
        //       (tick) => tick.TicketEstado && tick.abierto
        //     ).length,
        //   iconName: "circle-exclamation",
        //   color: "rgba(223, 18, 46, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     console.log("Deactivate");
        //   },
        // },
        {
          title: "Tickets Abiertos",
          value: () =>
            this.listaItems.length,
          iconName: "folder-open",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },

        {
          title: "Prioridad Alta",
          value: () =>
            this.listaItems.filter(
              (tick) => tick.prioridad == 3
            ).length,
          iconName: "circle-exclamation",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Alta").draw();
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
            console.log("Desactivate");
          },
        },
        {
          title: "Prioridad Media",
          value: () =>
          this.listaItems.filter(
              (tick) => tick.prioridad == 2
            ).length,
          iconName: "bell",
          color: "rgba(254, 114, 28, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("media").draw();
            console.log("Activated");
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
            console.log("Deactivate");
          },
        },
        {
          title: "Prioridad baja",
          value: () =>
          this.listaItems.filter(
              (tick) => tick.prioridad == 1
            ).length,
          iconName: "hand-point-up",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            this.dataTableInstance.search("Baja").draw();
            console.log("Activated");
          },
          onDeactivate: () => {
            this.dataTableInstance.search("").draw();
            console.log("Deactivate");
          },
        },
      ]
    };
  },
  async mounted() {
    try {
      // this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      this.dataTableInstance = window.$("#datatable_numeraciones").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      // this.$isLoading(false);
    } catch (error) {
      // this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    verMensajes(item) {
      try {
        console.log(item);
        // let enlace = this.$router.resolve({ path: "/helex/ChatTicket/" + item.persona.id + "/" + item.id });
        // window.open(enlace.href, "_blank");
        this.$router.push(
          "/helex/ChatTicket/" + item.persona.id + "/" + item.id
        );
      } catch (error) {
        console.log("err", error);
      }
    },
    async cerrarTicket(item) {
      Swal.fire({
        title: "Seguro cerrar Ticket?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          item.Estado = 0;
          //let loader = this.$loading.show();
          // let data = {
          //   Id: item.id,
          //   Estado : 0,
          //   Fecha: this.$moment.tz(item.fecha, "America/Bogota").format("YYYY-MM-DDTHH:mm")
          // }
          let res = await this.$store.dispatch("hl_post", {
            path: "PersonaTicket/ActualizaTicket",
            data: item,
          });
          if (res == true) {
            await this.getData();
            Swal.fire("Listo!", "Ticket cerrado correctamente.", "success");
          } else {
            Swal.fire("Error!", "Intentalo de nuevo.", "error");
          }
        }
      });
    },
    async getData() {
      try {
        console.log(".....................");
        let res;
        if (this.usuario.rol.nivel == 4) {
          res = await this.$store.dispatch("hl_get", {
            path: "PersonaTicket/GetTickets/1",
          });
        } else {
          res = await this.$store.dispatch("hl_get", {
            path:
              "PersonaTicket/GetTicketByPersonaId/" + this.usuario.id + "/1",
          });
            console.log('lista tickets',res)
        }

        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaTickets = await this.$store.dispatch("hl_get", {
            path: "PersonaTicket/GetOpenTickets",
          });
          for await (let it of res) {
            let TicketEstado = this.listaTickets.find(
              (x) => x.ticketId == it.id
            );
            if (TicketEstado != undefined) {
              if (this.usuario.rol.nivel == 4) {
                it.estadoTicket =
                  (TicketEstado.paraId == null ||
                    TicketEstado.paraId == this.usuario.id) &&
                  TicketEstado.estado == 1
                    ? true
                    : false;
              } else {
                it.estadoTicket =
                  TicketEstado.paraId == this.usuario.id &&
                  TicketEstado.estado == 1
                    ? true
                    : false;
                //this.listaTickets = res.filter((x) => x.paraId == this.usuario.id && x.estado == 1);
              }
            }

            //it.estadoTicket =  TicketEstado.para TicketEstado.estado
          }
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
